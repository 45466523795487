import { graphql } from "gatsby";
import React from "react";
import { isSafari } from 'react-device-detect';

import SEO from "../components/seo"
import Layout from "../components/layout"

import "./partnersContentPage.scss";

const nl2br = (str, is_xhtml) => {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2').replace(/&gt;/g, '>').replace(/&lt;/g, '<');
}

const PartnersContentPage = (queryData) => {
    let title = '';
    let description = '';
    let content = '';
    let slug = '';

    const partnerContentPage = queryData.data.gcms.partnersContentPage;

    // const [urlParam, setUrlParam] = useState("");

    if (partnerContentPage != null) {
        if (partnerContentPage.pageTitle != null) {
            title = partnerContentPage.pageTitle;
        }
        if (partnerContentPage.pageMetaDescription != null) {
            description = partnerContentPage.pageMetaDescription;
        }
        if (partnerContentPage.pageContent != null && partnerContentPage.pageContent.html != null) {
            content = nl2br(partnerContentPage.pageContent.html);
        }
        if (partnerContentPage.slug != null) {
            slug = partnerContentPage.slug;
        }
    }

    // useEffect(() => {
    //     setUrlParam(window.location.pathname.replace(/\//g, ''));
    // }, [urlParam]);

    return (
        <Layout>
            <SEO title={title} description={description} urlParams={'partners/' + slug} />
            <img className="print print-image" src={isSafari === true ? 'https://media.travelspirit.nl/original/sm/TravelSpirit_Logo-FC.png' : 'https://media.travelspirit.nl/original/smw/TravelSpirit_Logo-FC.webp'} alt="logo" loading="lazy" />
            <span className="content-page-container">
                <div className="content-container container">
                    <div className="inner-container">
                        <div className="html-text" dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                </div>
            </span>
        </Layout>
    )
}

export default PartnersContentPage

export const pageQuery = graphql`
query PartnersContentPageQuery($id: ID) {
    gcms {
        partnersContentPage(where: { id: $id }) {
            pageTitle
            pageMetaDescription
            pageContent { html }
            slug
        }
    }
}`;
